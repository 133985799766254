import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';

import { Routes, Route, BrowserRouter } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';

import LuckWheelPage from './pages/LuckyWheelPage';
import LuckGridPage from './pages/LuckyGridPage';
import LuckSlotPage from './pages/LuckySlotPage';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<LuckWheelPage/>} />
      <Route path="/wheel" element={<LuckWheelPage/>} />
      <Route path="/grid" element={<LuckGridPage/>} />
      <Route path="/slot" element={<LuckSlotPage/>} />
    </Routes>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
