import React, { useRef, useState } from "react";
import { LuckyGrid } from '@lucky-canvas/react'

function LuckyGridPage() {

    const [blocks] = useState([
        { padding: '10px', background: '#869cfa' },
        { padding: '10px', background: '#e9e8fe' },
    ])
    const [prizes] = useState([
        { x: 0, y: 0 },
        { x: 1, y: 0 },
        { x: 2, y: 0 },
        { x: 2, y: 1 },
        { x: 2, y: 2 },
        { x: 1, y: 2 },
        { x: 0, y: 2 },
        { x: 0, y: 1 },
    ])
    const [defaultStyle] = useState({
        background: '#b8c5f2'
    })

    const [buttons] = useState([
        {
            x: 1, y: 1,
            background: '#9c9dd8',
            fonts: [{ text: '开始', top: '25%' }],
        },
    ])

    const myLucky = useRef()

    return <div>
        <LuckyGrid
            ref={myLucky}
            width="300px"
            height="300px"
            blocks={blocks}
            prizes={prizes}
            buttons={buttons}
            defaultStyle={defaultStyle}
            onStart={() => { // 点击抽奖按钮会触发star回调
                myLucky.current.play()
                setTimeout(() => {
                    // TODO 这里请求后端，获取中奖index，之后通过myLucky.current.stop(index)来停止转盘
                    const index = Math.random() * 9 >> 0
                    myLucky.current.stop(index)
                }, 2500)
            }}
            onEnd={prize => { // 抽奖结束会触发end回调
                alert('恭喜你抽到 ' + JSON.stringify(prize)+ ' 号奖品')
            }}
        />
    </div>
}
export default LuckyGridPage;