import React, { useRef, useState } from "react";

import { SlotMachine } from '@lucky-canvas/react'
import ResultDialog from "../components/ResultDialog";
import ErrorMessageDialog from "../components/ErrorMessageDialog";
import ConfirmPayDialog from "../components/ConfirmPayDialog";
import LotteryDescription from "../components/LotteryDescription";

import { getTask, getPrizes, submit, isCanJoin, isEnough } from "../api/api";
import { useSearchParams } from "react-router-dom";

class _LuckySoltPage extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            lottering: false,
            error: '',
            showResult: false,

            task: null,
            blocks: [
                // { padding: '10px', background: '#869cfa' }
                { padding: '10px', background: '#869cfa' },
                // { padding: '10px', background: '#e9e8fe' },
            ],
            prizes: [],
            xprizes: [],

            slots: [
                { speed: 3, direction: -1 },
                { speed: 6, direction: 1 },
                { speed: 9, direction: -1 },
            ],
            defaultConfig: {
                rowSpacing: '10px',
                colSpacing: '10px'
            },
            lotteryResultIndex: null
        }

        this.confirmPayDialog = React.createRef()
        this.errorMessageDialog = React.createRef()
        this.resultDialog = React.createRef()
    }


    componentDidMount() {
        this._loadTask()
        this._loadPrizes()
    }

    _loadTask = async () => {
        const res = await getTask(this.props.id)
        const task = res.data
        this.setState({ task })
    }

    _loadPrizes = async () => {
        const res = await getPrizes(1)
        const thePrize = res.data

        const prizes = []

        // iterator the prize

        for (let i = 0; i < thePrize.length; i++) {
            const prize = thePrize[i]
            const color = this._getColor(i)
            const prizeItem = {
                background: color,
                imgs: [
                    { src: prize.image, width: '60%', top: '20%' }
                ],
                borderRadius: '10px',
                name: prize.title,
                // fonts: [{ text: prize.shortName }]
            }
            prizes.push(prizeItem)
        }

        this.setState({ prizes, xprizes: thePrize })
    }

    _getColor(index) {
        const colors = ['#ffcccc', '#ffff99', '#ccccff', '#ffccff', '#ccffcc', '#ff9999']
        return colors[index % colors.length]
    }

    lottery = async () => {
        const myLucky = this.props.myLucky
        if (this.state.lottering) {
            return
        }

        this.setState({ lottering: true })
        const taskid = this.state.task.id

        const res1 = await isCanJoin(taskid)

        if (res1.code !== 1 || !res1.data) {
            this.setState({ lottering: false })
            this.errorMessageDialog.current.show('活动条件限制，您无法参加本次抽奖')
            return
        }

        const res2 = await isEnough(taskid)
        if (res2.code !== 1 || !res2.data) {
            this.setState({ lottering: false })
            this.errorMessageDialog.current.show('您的余额不足，无法参加本次抽奖')
            return
        }

        // check time
        const now = new Date()
        const startTime = new Date(this.state.task.startTime)
        const endTime = new Date(this.state.task.endTime)
        if (now < startTime) {
            this.setState({ lottering: false })
            this.errorMessageDialog.current.show('活动尚未开始')
            return
        }
        if (now > endTime) {
            this.setState({ lottering: false })
            this.errorMessageDialog.current.show('活动已经结束')
            return
        }

        // check status
        if (this.state.task.status !== 1) {
            this.setState({ lottering: false })
            this.errorMessageDialog.current.show('活动暂不可用')
            return
        }

        const res = await submit(taskid);
        if (res.code === 1) {
            myLucky.current.play()
            const index = res.data
            this.setState({ lotteryResultIndex: index })
            myLucky.current.stop(index)
        } else {
            this.errorMessageDialog.current.show(res.msg)
        }
        this.setState({ lottering: false })
    }

    render() {
        const { blocks, prizes, slots, xprizes, defaultConfig } = this.state
        const myLucky = this.props.myLucky
        const task = this.state.task

        console.log('proze length:', prizes.length)
        return <div style={
            {
                position: 'absolute',
                left: 0,
                top: 0,
                right: 0,
                bottom: 0,
                background: '#ff6666'
            }
        } >
            <div style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: '1rem',
                marginRight: '1rem'
            }}>
                <img src={require('../assets/image/logs.png')} style={{
                    width: '2rem',
                    height: '2rem',
                    borderRadius: '0.8rem',
                }} />
            </div>
            {/* <div style={{
                color: 'white',
                fontSize: '1.2rem',
            }}
            >{task ? task.title : 'Loading...'}</div> */}
            {/* center */}
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: '2rem'
            }}>
                {prizes && prizes.length > 0 ? <SlotMachine
                    ref={myLucky}
                    height="220px"
                    width=""
                    blocks={blocks}
                    slots={slots}
                    prizes={prizes}
                    defaultConfig={defaultConfig}
                    onEnd={prize => { // 抽奖结束会触发end回调
                        // alert('恭喜你抽到 ' + JSON.stringify(prize) + ' 号奖品')
                        this.resultDialog.current.show(prize)
                    }}
                /> : null}
            </div>
            <div style={{
                display: 'flex',
                justifyContent: 'center',
            }}>
                <button style={{
                    width: '70%',
                    marginTop: '1rem',
                    height: '2.6rem',
                    backgroundColor: '#ffcc66',
                    borderRadius: '1.3rem',
                    fontSize: '1.2rem',
                    color: 'white',
                    border: 'none',
                    outline: 'none'

                }} onClick={() => {
                    this.confirmPayDialog.current.show()
                    // myLucky.current.play()
                    // setTimeout(() => {
                    //     this.lottery()
                    // }, 2500)
                }}>抽奖</button>
            </div>
            <div>
                <div>
                    {task && xprizes.length > 0 ? <LotteryDescription task={task} prizes={xprizes} />
                        : null}
                </div>

                <ResultDialog ref={this.resultDialog} onConfirm={(e) => { }} />
                {/* show error dialog */}
                <ErrorMessageDialog ref={this.errorMessageDialog} error={this.state.error} />

                <ConfirmPayDialog ref={this.confirmPayDialog} task={task} onConfirm={this.lottery} />
            </div>
        </div>
    }

}

function LuckySlotPage() {

    const myLucky = useRef()
    const [params] = useSearchParams()
    const id = params.get('id')

    return <div>
        <_LuckySoltPage myLucky={myLucky} id={id} />
    </div>
}

export default LuckySlotPage;




// function LuckySlotPage() {

//     const [blocks] = useState([
//         { padding: '10px', background: '#869cfa' },
//         { padding: '10px', background: '#e9e8fe' },
//     ])

//     const [slots] = useState([
//         // { order: [0, 1, 2], speed:3, direction: -1 },
//         // { order: [2, 0, 1], speed:3, direction: 1  },
//         // { order: [1, 2, 0], speed:3, direction: -1  },

//         {speed:3, direction: -1 },
//         {speed:10, direction: 1  },
//         {speed:30, direction: -1  },
//     ])

//     const [prizes] = useState([
//         {
//             background: '#b6c8f6',
//             borderRadius: '10px',
//         },
//         {
//             background: '#8c9eed',
//             borderRadius: '10px',
//         },
//         {
//             background: '#6980e3',
//             borderRadius: '10px',
//         }
//     ])
//     const [defaultConfig] = useState({
//         rowSpacing: '10px',
//         colSpacing: '10px'
//     })

//     const myLucky = useRef()

//     return <div>
//         <SlotMachine
//             ref={myLucky}
//             width="240px"
//             height="180px"
//             blocks={blocks}
//             slots={slots}
//             prizes={prizes}
//             defaultConfig={defaultConfig}
//             onEnd={prize => { // 抽奖结束会触发end回调
//                 alert('恭喜你抽到 ' + JSON.stringify(prize) + ' 号奖品')
//             }}
//         />
//         <div>
//             <button onClick={() => {
//                 myLucky.current.play()
//             } }>开始</button>

//             <button onClick={() => {
//                 setTimeout(() => {
//                     // TODO 这里请求后端，获取中奖index，之后通过myLucky.current.stop(index)来停止转盘
//                     const index = Math.random() * 3 >> 0
//                     myLucky.current.stop(index)
//                 }, 2500)
//             }
//             }>停止</button>
//         </div>
//     </div>
// }

// export default LuckySlotPage;