import React  from "react";

class ResultDialog extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            show: false,
            result: null
        }

        // console
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)
    }

    show = (result) => {
        this.setState({ show: true, result })
    }

    hide = () => {
        this.setState({ show: false })
    }

    render() {
        return <div style={{
            display: this.state.show ? 'block' : 'none',
            position: 'absolute',
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            background: 'rgba(0,0,0,0.5)',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1001
        }}>
            <div style={{
                position: 'absolute',
                top: '25%',
                width: '80%',
                height: '200px',
                left: '10%',
                background: 'white',
                borderRadius: '0.5rem',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <div style={{
                    color: 'black',
                    fontSize: '1.2rem',
                    marginTop: '1rem',
                    paddingLeft: '1rem',
                    paddingRight: '1rem',

                }}>{this.state.result? this.state.result.name: 'Loading...'}</div>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: '1rem'
                }}>
                    <button onClick={() => {
                        this.hide()
                        if (this.props.onConfirm) {
                            this.props.onConfirm()
                        }
                    }} style={{
                        width: '100px',
                        height: '40px',
                        background: '#617df2',
                        color: 'white',
                        fontSize: '1.2rem',
                        borderRadius: '0.5rem',
                        border: 'none'
                    }}>确定
                    </button>
                </div>
            </div>
        </div>
    }
}

export default ResultDialog;