import React from "react";

class LotteryDescription extends React.Component {

    constructor(props) {
        super(props)
    }

    render() {
        const task = this.props.task
        const prizes = this.props.prizes

        return <div>
            <div style={{
                margin: '1.2rem',
                backgroundColor: '#ffffcc',
                padding: '1rem',
                borderRadius: '0.5rem',
            }}>
                <div style={{
                    fontSize: '1rem',
                    fontWeight: 'bold',
                    marginBottom: '0.5rem',
                }}>{task.title} 活动说明</div>
                <div style={{
                    fontSize: '0.8rem',
                    lineHeight: '1.5',
                }}>{task.description}</div>
                <div style={{height:'1.2rem'}}></div>
                {/* table display prizes */}
                <div style={{
                    fontSize: '1rem',
                    fontWeight: 'bold',
                    marginBottom: '0.5rem',
                }}>
                    奖品列表
                </div>
                <div style={{
                    // marginTop: '1rem',
                    // display: 'flex',
                    // flexWrap: 'wrap',
                }}>
                    {prizes.map((prize, index) => {
                        return <div key={index} style={{
                            display: 'flex',
                            padding: '0.5rem',
                            color: 'black',
                            alignItems: 'center',
                        }}>
                            <div style={{
                                width: '1rem',
                                height: '1rem',
                                borderRadius: '0.5rem',
                            }}>
                               <img src={prize.image} style={{
                                      width: '100%',
                                      height: '100%',
                                      borderRadius: '0.8rem',
                                 }}/>

                            </div>
                            <div style={{
                                marginLeft: '0.5rem',
                                fontSize: '0.8rem',
                                fontWeight: 'bold',
                            }}>{prize.title}</div>
                        </div>
                    })}
                </div>


            </div>
        </div>
    }
}

export default LotteryDescription;