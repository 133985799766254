import React, { useRef,  } from "react";
import { LuckyWheel } from '@lucky-canvas/react'

import { getTask, getPrizes, submit, isCanJoin, isEnough, isLogin, login, inAppLogin, setToken } from "../api/api";
import LotteryDescription from "../components/LotteryDescription";
import ConfirmPayDialog from "../components/ConfirmPayDialog";
import ErrorMessageDialog from "../components/ErrorMessageDialog";
import ResultDialog from "../components/ResultDialog";
import { useSearchParams } from "react-router-dom";

class _LuckyWheelPage extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            lottering: false,
            error: '',
            showResult: false,

            task: null,
            blocks: [
                { padding: '10px', background: '#869cfa' }
            ],
            prizes: [],
            xprizes: [],
            buttons: [
                { radius: '40%', background: '#617df2' },
                { radius: '35%', background: '#afc8ff' },
                {
                    radius: '30%', background: '#0099cc',
                    pointer: true,
                    fonts: [{ text: '抽奖', top: '-10px' }]
                }
            ],

            lotteryResultIndex: null
        }
        this.confirmPayDialog = React.createRef()
        this.errorMessageDialog = React.createRef()
        this.resultDialog = React.createRef()
    }

    componentDidMount() {
        this._loadTask()
        this._loadPrizes()
    }

    _loadTask = async () => {
        const res = await getTask(this.props.id)
        const task = res.data
        this.setState({ task })
    }

    _loadPrizes = async () => {
        const res = await getPrizes(1)
        const thePrize = res.data

        const prizes = []

        // iterator the prize

        for (let i = 0; i < thePrize.length; i++) {
            const prize = thePrize[i]
            const color = this._getColor(i)
            const prizeItem = {
                background: color,
                imgs: [
                    { src: prize.image, width: '40%', top: '10%' }
                ],
                name: prize.title,
                // fonts: [{ text: prize.shortName }]
            }
            prizes.push(prizeItem)
        }

        this.setState({ prizes, xprizes: thePrize })
    }

    _getColor(index) {
        const colors = ['#ffcccc', '#ffff99', '#ccccff', '#ffccff', '#ccffcc', '#ff9999']
        return colors[index % colors.length]
    }

    lottery = async () => {
        const myLucky = this.props.myLucky
        if (this.state.lottering) {
            return
        }

        this.setState({ lottering: true })
        const taskid = this.state.task.id

        const res1 = await isCanJoin(taskid)

        if (res1.code !== 1 || !res1.data) {
            this.setState({ lottering: false })
            this.errorMessageDialog.current.show('活动条件限制，您无法参加本次抽奖')
            return
        }

        const res2 = await isEnough(taskid)
        if (res2.code !== 1 || !res2.data) {
            this.setState({ lottering: false })
            this.errorMessageDialog.current.show('您的余额不足，无法参加本次抽奖')
            return
        }

        // check time
        const now = new Date()
        const startTime = new Date(this.state.task.startTime)
        const endTime = new Date(this.state.task.endTime)
        if (now < startTime) {
            this.setState({ lottering: false })
            this.errorMessageDialog.current.show('活动尚未开始')
            return
        }
        if (now > endTime) {
            this.setState({ lottering: false })
            this.errorMessageDialog.current.show('活动已经结束')
            return
        }

        // check status
        if (this.state.task.status !== 1) {
            this.setState({ lottering: false })
            this.errorMessageDialog.current.show('活动暂不可用')
            return
        }

        const res = await submit(taskid);
        if (res.code === 1) {
            myLucky.current.play()
            const index = res.data
            this.setState({ lotteryResultIndex: index })
            myLucky.current.stop(index)
        } else {
            this.errorMessageDialog.current.show(res.msg)
        }
        this.setState({ lottering: false })
    }

    render() {

        const { blocks, prizes, buttons, xprizes } = this.state
        const myLucky = this.props.myLucky
        const task = this.state.task
        return <div style={
            {
                position: 'absolute',
                left: 0,
                top: 0,
                right: 0,
                bottom: 0,
                background: '#ff6666'
            }
        } >
            <div style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: '1rem',
                marginRight: '1rem'
            }}>
                <img src={require('../assets/image/logs.png')} style={{
                    width: '2rem',
                    height: '2rem',
                    borderRadius: '0.8rem',
                }} />
            </div>
            {/* <div style={{
                color: 'white',
                fontSize: '1.2rem',
            }}
            >{task ? task.title : 'Loading...'}</div> */}
            {/* center */}
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: '2rem'
            }}>
                {prizes && prizes.length > 0 ? <LuckyWheel
                    ref={myLucky}
                    width="300px"
                    height="300px"
                    blocks={blocks}
                    prizes={prizes}
                    buttons={buttons}
                    onStart={() => { // 点击抽奖按钮会触发star回调
                        console.log('star', this.confirmPayDialog)
                        this.confirmPayDialog.current.show()
                    }}
                    onEnd={prize => { // 抽奖结束会触发end回调
                        // alert('恭喜你抽到 ' + prize.name)
                        console.log('end', prize)
                        this.resultDialog.current.show(prize)
                    }}
                /> : null}
            </div>

            <div>
                {task && xprizes.length > 0 ? <LotteryDescription task={task} prizes={xprizes} />
                    : null}
            </div>

            <ResultDialog ref={this.resultDialog} onConfirm={(e) => { }} />
            {/* show error dialog */}
            <ErrorMessageDialog ref={this.errorMessageDialog} error={this.state.error} />

            <ConfirmPayDialog ref={this.confirmPayDialog} task={task} onConfirm={this.lottery} />

        </div>
    }
}

function LuckWheelPage() {
    const myLucky = useRef()

    // params
    const [params] = useSearchParams()
    const id = params.get('id')

    const hashParams = new URLSearchParams(window.location.hash)

    if (hashParams) {
        const initDataRaw = hashParams.get('tgWebAppData')
        if (initDataRaw) {
            // 在TG里
            const initData = new URLSearchParams(initDataRaw);
            console.log('initData', initData)

            // 使用initData发送到服务器换取login token
            inAppLogin(initData).then(res => {
                console.log('inAppLogin', res)
                if (res.code === 1) {
                    const token = res.data
                    setToken(token)
                    window.location.hash = ''
                }
            })
        }
    }

    return <div>
        <_LuckyWheelPage myLucky={myLucky} id={id} />
    </div>
}


export default LuckWheelPage;