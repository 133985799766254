import axios from 'axios';
// post to api from parameters

import { API } from './const';

const getToken = () => {
    return localStorage.getItem('token');
}

export const post = (url, params, requestHeaders) => {
    const headers = {
        'token': getToken() || ''
    }
    // 
    if (requestHeaders) {
        Object.assign(headers, requestHeaders)
    }
    
    return new Promise((resolve, reject) => {
        axios({
            method: 'post',
            url: API + url,
            data: params,
            headers
        }).then(response => {
            resolve(response.data);
        }
        ).catch(error => {
            reject(error);
        }
        );
    });
};

// post json to api
export const postJson = (url, params) => {
    return new Promise((resolve, reject) => {
        axios({
            method: 'post',
            url: API + url,
            data: params,
            headers: {
                'Content-Type': 'application/json',
                'token': getToken() || ''
            }
        }).then(response => {
            resolve(response.data);
        }
        ).catch(error => {
            reject(error);
        }
        );
    });
};

// get from api use axios   
export const get = (url, params) => {
    return new Promise((resolve, reject) => {
        axios({
            method: 'get',
            url: API + url,
            params: params,
            headers: {
                'token': getToken() || ''
            }
        }).then(response => {
            resolve(response.data);
        }
        ).catch(error => {
            reject(error);
        }
        );
    });
};
