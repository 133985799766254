import React from "react";

class ConfirmPayDialog extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            show: false
        }

        // console
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)
    }

    show = () => {
        this.setState({ show: true })
    }

    hide = () => {
        this.setState({ show: false })
    }

    render() {
        return <div style={{
            display: this.state.show ? 'block' : 'none',
            position: 'absolute',
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            background: 'rgba(0,0,0,0.5)',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1000
        }}>
            {/* 你确定要支付xxx 来参加抽奖码？ */}
            <div style={{
                position: 'absolute',
                top: '25%',
                width: '80%',
                height: '200px',
                left: '10%',
                background: 'white',
                borderRadius: '0.5rem',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <div style={{
                    color: 'black',
                    fontSize: '1.2rem',
                    marginTop: '1rem',
                    paddingLeft: '1rem',
                    paddingRight: '1rem',

                }}>你确定要支付<b> {this.props.task && this.props.task.cost} {this.props.task && this.props.task.symbol}</b> 来参加抽奖码？</div>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-evenly',
                    alignItems: 'center',
                    marginTop: '1.6rem',
                    width: '100%'
                }}>
                    <div style={{
                        height: '2rem',
                        background: 'red',
                        borderRadius: '0.5rem',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: 'white',
                        fontSize: '1.2rem',
                        padding: '0.5rem',
                        paddingLeft: '1rem',
                        paddingRight: '1rem',
                    }} onClick={(e) => {
                        this.setState({ show: false })
                        // this.lottery()
                        if (this.props.onConfirm) {
                            this.props.onConfirm()
                        }
                    }}>确定</div>
                    <div style={{
                        height: '2rem',
                        padding: '0.5rem',
                        paddingLeft: '1rem',
                        paddingRight: '1rem',
                        background: 'unset',
                        border: '1px solid red',
                        borderRadius: '0.5rem',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: 'black',
                        fontSize: '1.2rem'
                    }} onClick={(e) => {
                        this.setState({ show: false })
                        if (this.props.onCancel) {
                            this.props.onCancel()
                        }
                    }} >取消</div>
                </div>
            </div>
        </div>
    }
}

export default ConfirmPayDialog;