import {get, post, postJson} from './rest';

export const isLogin = () => {
    return localStorage.getItem('token') !== null;
}

export const setToken = (token) => {
    localStorage.setItem('token', token);
}

export const login = (params) => {
    return post('/api/tg/login', params);
}

export const inAppLogin = (initData) => {
    return post('/api/tg/inapp/login', {}, {
        'Authorization': `tma ${initData}`,
    });
}

export const getTask = (id) => {
    return get( `/api/lucky/task/get/${id}`);
}

export const isCanJoin = (id) => {
    return get(`/api/lucky/task/allow/${id}`);
}

export const isEnough = (id) => {
    return get(`/api/lucky/task/enough/${id}`);
}
// logs
export const getLogs = (params) => {
    return get('/api/lucky/task/logs/', params);
}
// task submit
export const submit = (id) => {
    return post(`/api/lucky/task/submit/${id}`);
}

// task prizes
export const getPrizes = (id) => {
    return get(`/api/lucky/task/prizes/${id}`);
}

